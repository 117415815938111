import axios from 'axios'

export default {
  getInvoiceDetails (limit: any, page: any) {
    return axios.get('invoices?limit=' + limit + '&page=' + page)
  },

  getCreditAmount (data: any)  {
    return axios.post('get-price', data)
  },

  getStripeToken (data: any)  {
    return axios.post('stripe/get-token', data)
  },

  getInvoicePDF (id: any) {
    return axios.get('invoice/' + id + '/export_pdf')
  },

  getAllpriceLists () {
    return axios.get('prices')
  }

}
