
import { defineComponent, ref, onMounted, createVNode } from "vue";
import BillingService from "../services/billing";
import { notification } from "ant-design-vue";
import moment, { Moment } from "moment";
import router from "../router";
import {
  CheckCircleOutlined,
  EyeOutlined,
  UndoOutlined,
  CloseCircleFilled,
  EyeInvisibleOutlined,
} from "@ant-design/icons-vue";
import commonService from "../services/common";

declare const Stripe: any;

export default defineComponent({
  emits: ["updateCreditPoint"],
  components: {
    CheckCircleOutlined,
    EyeOutlined,
    UndoOutlined,
    CloseCircleFilled,
    EyeInvisibleOutlined,
  },
  setup(props, { emit }) {
    const pageLoder = ref<boolean>(false);
    const showcheckoutpage = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const totalCredits = ref();
    const invoiceLists = ref([]);
    const totalCost = ref<any>();
    const agencyDetails = ref();
    const avilableCredit = ref();
    const publishableKey = ref();
    const stripe = ref();
    const card = ref();
    const elements = ref();
    const pageSize = ref(10);
    const currentPage = ref(1);
    const totalNoofInvoices = ref();
    const pricelistVisible = ref<boolean>(false);
    const allPriceLists = ref([]);
    const allPriceColumns = [
      {
        title: "Credits Upto",
        dataIndex: "credits_till",
      },
      {
        title: "Price per credit",
        dataIndex: "price_per_unit",
        slots: { customRender: "price" },
      },
    ]
    const limitNumber = (value: any) => {
      if (typeof value === "string") {
        // eslint-disable-next-line no-useless-escape
        return !isNaN(Number(value)) ? value.replace(new RegExp(/[^0-9\\]/g), "") : 0;
      } else if (typeof value === "number") {
        // eslint-disable-next-line no-useless-escape
        return !isNaN(value) ? String(value).replace(new RegExp(/[^0-9\\]/g), "") : 0;
      } else {
        return 0;
      }
    };
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const onShowSizeChange = (current: number, pageSizes: number) => {
      pageSize.value = pageSizes;
      getInvoiceDetails();
    };
    const onPageChange = () => {
      getInvoiceDetails();
    };
    const onCreditBlur = async () => {
      if (totalCredits.value !== null && totalCredits.value !== undefined) {
        pageLoder.value = true;
        const data = {
          credits: totalCredits.value,
        };
        try {
          const res = await BillingService.getCreditAmount(data);
          if (res.data.data.success) {
            totalCost.value = res.data.data.price;
          }
          pageLoder.value = false;
        } catch (error: any) {
          if (error.response) {
            console.log("Error", error.response);
          }
          pageLoder.value = false;
        }
      } else {
        totalCost.value = null
      }
    };
    const invoiceColumns = [
      {
        title: "Invoice Number",
        dataIndex: "invoice_number",
      },
      {
        title: "Date",
        dataIndex: "created_at",
        slots: { customRender: "date" },
      },
      {
        title: "Credits",
        dataIndex: "credits",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        slots: { customRender: "price" },
      },
      {
        title: "View",
        key: "view",
        align: "center",
        slots: { customRender: "action" },
      },
    ];
    const checkoutPage = async () => {
      if (totalCredits.value !== null && totalCredits.value !== undefined) {
        showcheckoutpage.value = true;
        setTimeout(() => {
          var stripe = Stripe(publishableKey.value);
          var elements = stripe.elements({
            fonts: [
              {
                cssSrc:
                  "https://fonts.googleapis.com/css?family=Source+Code+Pro",
              },
            ],
          });

          // Floating labels
          var inputs = document.querySelectorAll(
            ".cell.paymentSec.stripeCElement .input"
          );
          Array.prototype.forEach.call(inputs, function (input) {
            input.addEventListener("focus", function () {
              input.classList.add("focused");
            });
            input.addEventListener("blur", function () {
              input.classList.remove("focused");
            });
            input.addEventListener("keyup", function () {
              if (input.value.length === 0) {
                input.classList.add("empty");
              } else {
                input.classList.remove("empty");
              }
            });
          });

          var elementStyles = {
            base: {
              color: "#32325D",
              fontWeight: 500,
              fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
              fontSize: "16px",
              fontSmoothing: "antialiased",

              "::placeholder": {
                color: "#CFD7DF",
              },
              ":-webkit-autofill": {
                color: "#e39f48",
              },
            },
            invalid: {
              color: "#E25950",

              "::placeholder": {
                color: "#FFCCA5",
              },
            },
          };

          var elementClasses = {
            focus: "focused",
            empty: "empty",
            invalid: "invalid",
          };

          var cardNumber = elements.create("cardNumber", {
            style: elementStyles,
            classes: elementClasses,
          });
          cardNumber.mount("#stripeCElement-card-number");

          var cardExpiry = elements.create("cardExpiry", {
            style: elementStyles,
            classes: elementClasses,
          });
          cardExpiry.mount("#stripeCElement-card-expiry");

          var cardCvc = elements.create("cardCvc", {
            style: elementStyles,
            classes: elementClasses,
          });
          cardCvc.mount("#stripeCElement-card-cvc");

          registerElements([cardNumber, cardExpiry, cardCvc], "stripeCElement");

          function registerElements(elements: any, stripeElementName: string) {
            var formClass = "." + stripeElementName;
            var example: HTMLElement = document.querySelector(formClass);

            var form = example.querySelector("form");
            var resetButton: HTMLElement = example.querySelector("a.reset");
            var error: HTMLElement = form.querySelector(".error");
            var errorMessage: HTMLElement = error.querySelector(".message");

            function enableInputs() {
              Array.prototype.forEach.call(
                form.querySelectorAll(
                  "input[type='text'], input[type='email'], input[type='tel']"
                ),
                function (input) {
                  input.removeAttribute("disabled");
                }
              );
            }

            function disableInputs() {
              Array.prototype.forEach.call(
                form.querySelectorAll(
                  "input[type='text'], input[type='email'], input[type='tel']"
                ),
                function (input) {
                  input.setAttribute("disabled", "true");
                }
              );
            }

            function triggerBrowserValidation() {
              // The only way to trigger HTML5 form validation UI is to fake a user submit
              // event.
              var submit = document.createElement("input");
              submit.type = "submit";
              submit.style.display = "none";
              form.appendChild(submit);
              submit.click();
              submit.remove();
            }

            // Listen for errors from each Element, and show error messages in the UI.
            var savedErrors: any = {};
            elements.forEach(function (element: any, idx: any) {
              element.on("change", function (event: any) {
                if (event.error) {
                  error.classList.add("visible");
                  savedErrors[idx] = event.error.message;
                  errorMessage.innerText = event.error.message;
                } else {
                  savedErrors[idx] = null;

                  // Loop over the saved errors and find the first one, if any.
                  var nextError = Object.keys(savedErrors)
                    .sort()
                    .reduce(function (maybeFoundError, key) {
                      return maybeFoundError;
                    }, null);

                  if (nextError) {
                    // Now that they've fixed the current error, show another one.
                    errorMessage.innerText = nextError;
                  } else {
                    // The user fixed the last error; no more errors.
                    error.classList.remove("visible");
                  }
                }
              });
            });

            // Listen on the form's 'submit' handler...
            form.addEventListener("submit", async function (e) {
              e.preventDefault();

              // Trigger HTML5 validation UI on the form if any of the inputs fail
              // validation.
              var plainInputsValid = true;
              Array.prototype.forEach.call(
                form.querySelectorAll("input"),
                function (input) {
                  if (input.checkValidity && !input.checkValidity()) {
                    plainInputsValid = false;
                    return;
                  }
                }
              );
              if (!plainInputsValid) {
                triggerBrowserValidation();
                return;
              }

              // Show a loading screen...
              example.classList.add("submitting");

              // Disable all inputs.
              disableInputs();

              // Gather additional customer data we may have collected in our form.
              var name: HTMLInputElement = form.querySelector(
                "#" + stripeElementName + "-name"
              );
              var additionalData = {
                name: name ? name.value : undefined,
              };

              // Use Stripe.js to create a token. We only need to pass in one Element
              // from the Element group in order to create a token. We can also pass
              // in the additional customer data we collected in our form.

              stripe
                .createToken(elements[0], additionalData)
                .then(async function (result: { token: { id: any } }) {
                  // Stop loading!
                  if (result.token) {
                    // If we received a token, show the token ID.
                    const data = {
                      amount: totalCost.value.total * 100,
                      currency: "GBP",
                      credits: totalCredits.value,
                    };
                    try {
                      const res = await BillingService.getStripeToken(data);
                      if (res.data.data.success) {
                        const result = await stripe.confirmCardPayment(
                          res.data.data.client_secret,
                          {
                            payment_method: {
                              card: elements[0],
                              billing_details: {
                                name: additionalData.name,
                                email: agencyDetails?.value.email,
                              },
                            },
                          }
                        );
                        example.classList.remove("submitting");
                        if (result.error) {
                          enableInputs();
                          await notify("Error", result.error.message, "error");
                        } else {
                          if (result.paymentIntent.status === "succeeded") {
                            example.classList.add("submitted");
                            // transactionSuccess.value = true
                            // activateClient(result.paymentIntent)
                          }
                        }
                      }
                      example.classList.remove("submitting");
                    } catch (error: any) {
                      if (error.response) {
                        example.classList.remove("submitting");
                        await notify(
                          "Error",
                          error.response.data.data.message,
                          "error"
                        );
                      }
                    }
                    // console.log(result.token.id);
                    // example.classList.add("submitted");
                  } else {
                    // Otherwise, un-disable inputs.
                    example.classList.remove("submitting");
                    enableInputs();
                  }
                });
            });

            resetButton.addEventListener("click", function (e) {
              e.preventDefault();
              // Resetting the form (instead of setting the value to `''` for each input)
              // helps us clear webkit autofill styles.
              form.reset();

              // Clear each Element.
              elements.forEach(function (element: any) {
                element.clear();
              });

              // Reset error state as well.
              error.classList.remove("visible");

              // Resetting the form does not un-disable inputs, so we need to do it separately:
              enableInputs();
              example.classList.remove("submitted");
            });
          }
        }, 100);
      } else {
        await notify("Error", "Please enter the number of credits.", "error");
      }
    };
    const canclePayment = async () => {
      showcheckoutpage.value = false;
    };
    const payementSucesfull = async () => {
      showcheckoutpage.value = false;
      getInvoiceDetails();
    };
    const getInvoiceDetails = async () => {
      totalCredits.value = null;
      totalCost.value = null;
      pageLoder.value = true;
      const limit = pageSize.value;
      const page = currentPage.value;
      try {
        const res = await BillingService.getInvoiceDetails(limit, page);
        if (res.data.data.success) {
          avilableCredit.value = res.data.data.credits.available_credits;
          invoiceLists.value = res.data.data.invoices.data;
          localStorage.available_credits =
            res.data.data.credits.available_credits;
          totalNoofInvoices.value = res.data.data.invoices.total;
          emit("updateCreditPoint");
        }
        pageLoder.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
        }
        pageLoder.value = false;
      }
    };
    const viewInvoice = async (data: any) => {
      pageLoder.value = true;
      const id = data.id;
      try {
        const res = await BillingService.getInvoicePDF(id);
        if (res.data.data.success) {
          window.open(res.data.data.invoice);
        }
        pageLoder.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
          pageLoder.value = false;
        }
      }
    };
    const viewPriceList = async () => {
      pricelistVisible.value = true
    }
    const clocePriceList = async () => {
      pricelistVisible.value = false
    }
    const getPriceLists = async() => {
      try {
        const res = await BillingService.getAllpriceLists();
        if (res.data.data.success) {
         allPriceLists.value = res.data.data.prices
        }
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
        }
      }
    }
    onMounted(async () => {
      getInvoiceDetails();
      getPriceLists();
      agencyDetails.value = JSON.parse(localStorage.getItem("profile"));
      publishableKey.value = process.env.VUE_APP_STRIP_PUBLIC_KEY;
    });
    return {
      moment,
      pageLoder,
      totalCredits,
      limitNumber,
      onCreditBlur,
      totalCost,
      invoiceColumns,
      invoiceLists,
      publishableKey,
      showcheckoutpage,
      checkoutPage,
      loading,
      notify,
      canclePayment,
      agencyDetails,
      payementSucesfull,
      avilableCredit,
      viewInvoice,
      commonService,
      pageSize,
      currentPage,
      totalNoofInvoices,
      onShowSizeChange,
      onPageChange,
      pricelistVisible,
      viewPriceList,
      clocePriceList,
      allPriceColumns,
      allPriceLists
    };
  },
  created: function () {
    this.moment = moment;
  },
});
